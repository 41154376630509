import React, { Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL } from '../../config';
import Spinner from '../elements/Spinner/Spinner';
import CarouselCredits from '../elements/Carousel/CarouselCredits';
import CarouselLatest from '../elements/Carousel/CarouselLatest';
import { Link } from 'react-router-dom';
import Footer from '../App/Footer';






class ActorInfo extends Component {



  state = {
    credits: [],
    actorId: null,
    movieId: null, 
    movieName: null, //'Sinbad', //this.props.match.params,
    movie:null,
    loading: false
  }


// console.log(this.state) X
  
  componentDidMount() {

    document.getElementById("scriptMenuDiv").style.display="none";
    document.getElementById("adDiv").style.display="inline";
    
  //window.addEventListener('scroll', this.listenToScroll)
  window.scroll({
    top: 0, 
    left: 0, 
    behavior: 'smooth'
  });
  const { actorId  } = this.props.match.params;
  const movieName = this.props.location.moviename;
  const movieId = this.props.location.movieid;

  // const { movieName } = this.props.match.params;
  // this.getState({ this.movieId });
  //console.log(this.props)

  document.getElementById("mms_navbar").style.position = "relative";
  

  if (localStorage.getItem(`${actorId}`)) {
    let state = JSON.parse(localStorage.getItem(`${actorId}`))
    this.setState({ ...state })
  } else {
    this.setState({ loading: true })
    let endpoint = `${API_URL}person/${actorId}?api_key=${API_KEY}&language=en-US`;
    //let creditsendpoint = `${API_URL}person/${actorId}movie_credits?api_key=${API_KEY}&language=en-US`
    this.fetchItems(endpoint);
    //this.fetchCredits(creditsendpoint);
    }
  }
  
  

  componentWillUnmount() {
   // window.removeEventListener('scroll', this.listenToScroll)
  }
  







  fetchItems = (endpoint) => {
    const { actorId } = this.props.match.params;
    const movieName = this.props.location.moviename;
    const movieId = this.props.location.movieid;
    

    //console.log('ActorInfo.js: fetchitems This actor id is '+actorId+'\n'+movieName);

    fetch(endpoint)
    .then(result => result.json())
    .then(result => {

      if (result.status_code) {
        // If we don't find any movie
        this.setState({ loading: false });
      } else {
        this.setState({ actorId: result }, () => {

          // ... then fetch actors in the setState callback function

          let endpoint = `${API_URL}person/${actorId}?api_key=${API_KEY}&append_to_response=external_ids`;
          
          fetch(endpoint)
          .then(result => result.json())
          .then(result => {
            const actorId = this.props.match.params;
            const movieName = this.props.location.movieName;
            const movieId = this.props.location.movieId;
            const theactor = result.name;
            const known_for_department = result.known_for_department;
            const birthday = result.birthday;
            const deathday = result.deathday;
            const gender = result.gender;
            const biography = result.biography;
            const popularity = result.popularity;
            const place_of_birth = result.place_of_birth;
            const profile_path = result.profile_path;
            const imdb_id = result.imdb_id;
            const homepage = result.homepage;

            //console.log('aid:'+actorId+'\nm:'+movieName+'\nID:'+movieId)

            this.setState({
              actorId,
              movieId,
              theactor,
              known_for_department,
              birthday,
              deathday,
              gender,
              biography,
              popularity,
              place_of_birth,
              profile_path,
              imdb_id,
              homepage,
              movieName,
              loading: false
            }, () => {
              localStorage.setItem(`${actorId}`, JSON.stringify(this.state));
              localStorage.setItem(`${movieName}`, JSON.stringify(this.state));
            })
          })
        })
      }
    })
    .catch(error => console.error('Error:', error))
  }




  loadIMDB (imdblink) {
    window.open("https://www.imdb.com/name/" +imdblink, "_blank");
  }
  loadIMDB1 (imdblink) {
    window.open("https://www.imdb.com/name/" +imdblink, "_blank");
  }
  g0toActor (theactor) {
    var str = theactor.split(' ').join('+');
    window.open("https://g0.to/?q="+str, "_blank");
  }

  render() {
    // ES6 Destructuring the props and state
    const {  credits,theactor,birthday,deathday,biography,place_of_birth,profile_path,imdb_id,
      homepage, loading } = this.state;

      const movieName = this.props.location.moviename;
      const movieId = this.props.location.movieid;
       const {actorId} = this.props.match.params;

    const POSTER_SIZE = "w154";

    
    return (
      <div style={{border:'solid red 0px'}}>
      <div style={{minHeight:'70%',zIndex: '2999'}}>
      {theactor ?
    <div style={{paddingLeft:'40px',fontFamily:'arial',display:'block'}}> 
        <br />
       {movieName ?
        <Link style={{textDecoration:'none'}} to={{pathname: `/${movieId}`}}>
        <span className="clickable" title={'Back to '+movieName} style={{fontSize:'30px',color:'black',textDecoration: 'none'}}>&#8592; </span>
        </Link>
        : null
       }
        
    <div style={{ fontSize:'34px', fontWeight:'bold',fontFamily:'arial black' }}><span style={{ fontSize:'20px',fontWeight: 'normsl',color:'grey',fontFamily:'arial' }}>
    Meet</span> <span id="actorinfo">{theactor}</span></div>
    <div style={{ width:'50%',float:'left',height:'auto',display:'inline',marginBottom:'50px'}}>
   <hr siae="1" />
   Born in {place_of_birth ? place_of_birth : ' - not listed'}  <span style={{float:'right'}}>{birthday}</span> 
   <hr siae="1" />
   
   <div style={{whiteSpace: 'pre-wrap',lineHeight:'23px',height:'', overflowY:'scroll',borderRight:'dotted 1px grey',padding:'15px'}}>

   {
     biography ? <span>{biography}</span> :<span>No bio currently available</span>
   }
   </div>
   <hr siae="1" />
    {deathday ? <span>Died {deathday}<hr siae="1"/></span> : null }

   {imdb_id ? 
   <div>
   <div title="Visit on IMDB" 
    onClick={ () => this.loadIMDB(imdb_id) }
    aria-hidden="true" 
    className="fa fa-imdb fa-2x fa-imdb imdbBtn" 
    style={{fontSize: '42px', cursor: 'pointer', zIndex: '100'}}>
    </div>
    <button onClick={() => this.g0toActor(theactor)} className="btn" title={'more about '+theactor} style={{backgroundImage:'url(https://g0.to/logo.png)',backgroundSize:'contain', width:'40px',height:'40px',border:'0px',marginLeft:'15px',cursor:'pointer'}}></button>
    <hr siae="1"/>
    </div>
   : null 
   } 
   Web: {homepage ? <a href={homepage}>{homepage}</a> : 'Not available'}
   <hr siae="1"/>
   {credits ?
    <div><a href="https://www.themoviedb.org/movie" style={{color:'blue',fontSize:'10px'}} rel="noopener noreferrer" target="_blank">edit Info</a> </div>
   : 
   <div><a href="https://www.themoviedb.org/movie" style={{color:'blue',fontSize:'10px'}} rel="noopener noreferrer" target="_blank">edit Info</a> </div>
   }

   </div>
   <div style={{height:'480px',position:''}}>
   <img
        src={profile_path ? `${IMAGE_BASE_URL}${POSTER_SIZE}${profile_path}` : '/images/no_image.jpg'}
        width="300" alt="actorthumb" align="right"
        onClick={ () => this.loadIMDB(imdb_id) }
        style={{position:'relative',marginLeft:'70px',right:'50px',cursor:'pointer',marginRight:'50px',border:'0px solid grey',borderTopRightRadius:'0px'}}
        />
        </div>
   </div>
        : null }
        {loading ? <Spinner /> : null}
    </div>
    
    <div style={{padding:'10px'}}>

{credits ? <CarouselCredits actorid={actorId} movieid={movieId} moviename={movieName} theactor={theactor}/> : <CarouselLatest />}
  
</div>

      <Footer />
   </div> 
   )
  }
}



export default ActorInfo;