import React, { Component } from 'react';
import { API_URL, API_KEY } from '../../config';
import Navigation from '../elements/Navigation/Navigation';
import { Link } from 'react-router-dom';
import MovieInfo from '../elements/MovieInfo/MovieInfo';
import MovieInfoBar from '../elements/MovieInfoBar/MovieInfoBar';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import Actor from '../elements/Actor/Actor';
import Spinner from '../elements/Spinner/Spinner';
import $ from "jquery";
import '../Movie/Movie.css';
import Footer from '../App/Footer';
import CarouselLatest from '../elements/Carousel/CarouselLatest';


 


class Watch extends Component {



  state = {
    movie: null,
    actors: null,
    directors: [],
    writers: [],
    loading: false,
    hasScript: 'no'
  }

  componentDidMount() {

    document.getElementById("scriptMenuDiv").style.display="none";
    document.getElementById("adDiv").style.display="inline";

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    window.addEventListener('scroll', this.listenToScroll)
    // ES6 destructuring the props
    const { movieId } = this.props.match.params;
    //console.log(movieId);
    if (localStorage.getItem(`${movieId}`)) {
      let state = JSON.parse(localStorage.getItem(`${movieId}`))
      this.setState({ ...state })
    } else {
      this.setState({ loading: true })
      // First fetch the movie ...
      let endpoint = `${API_URL}movie/${movieId}?api_key=${API_KEY}&language=en-US`;
      this.fetchItems(endpoint);
      let watchendpoint = `${API_URL}movie/${movieId}/watch/providers?api_key=${API_KEY}&language=en-US`;
      this.fetchItems(watchendpoint);
    }
  }





    
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      theposition: scrolled
    })
    document.documentElement.scrollTop > 600 ?  $("#mms_navbar").css({position: "relative"}).fadeIn(1000) : $("#mms_navbar").css({position: "relative"})
    }


  fetchItems = (watchendpoint) => {
    // ES6 destructuring the props
    const { movieId } = this.props.match.params;

    fetch(watchendpoint)
    .then(result => result.json())
    .then(result => {

      if (result.status_code) {
        // If we don't find any movie
        this.setState({ loading: false });
      } else {
        this.setState({ movie: result }, () => {
          // ... then fetch actors in the setState callback function
          let endpoint = `${API_URL}movie/${movieId}/credits?api_key=${API_KEY}`;
          fetch(endpoint)
          .then(result => result.json())
          .then(result => {

            const directors = result.crew.filter( (member) => member.job === "Director");
            const writers = result.crew.filter( (member) => member.job === "Screenplay" || member.job === "Writer");

            this.setState({
              actors: result.cast,
              directors,
              writers,
              loading: false
            }, () => {
              localStorage.setItem(`${movieId}`, JSON.stringify(this.state));
            })
          })
        })
      }
    })
    .catch(error => console.error('Error:', error))
  }
 
  render() {
    // ES6 Destructuring the props and state
    const { movieName } = this.props.location;
    const { movie, directors, actors, writers, loading } = this.state;
    let watch = `${movie}/watch/providers/`;
  
    return (
      <div className="rmdb-movie">
        {movie ?
        <div>
          <Navigation movie={movieName} />
          <MovieInfo movie={movie} directors={directors} writers={writers} imdbID={movie.imdb_id} />
          <MovieInfoBar time={movie.runtime} budget={movie.budget} revenue={movie.revenue} imdbID={movie.imdb_id}/>
        </div>
        : null }
        {actors ?
        <div className="rmdb-movie-grid">
        
          <FourColGrid header={'Actors'}>
          
            {actors.map( (element, i) => (
              <Actor key={i} actor={element} movieName={movie.title} movieId={movie.id}/>
            ))}
          </FourColGrid>
          <br /><br />
        </div>
        : null }
        {!actors && !loading ? <h1>No movie found</h1> : null }
        {loading ? <Spinner /> : null}
        <div style={{margin:'auto auto',width:'500px'}}><a href="https://touchpoint.video" target="_blank" rel="noopener noreferrer"><img src="https://touchpoint.video/images/mms_468x60.png"  title="Visit Touchpoint Video and promote your business with animation" alt="Visit Touchpoint Video and promote your business with animation" /></a></div>  
        <br /><br />        
         
        <Footer />
    </div>
    )
  }
}

export default Watch;