import React from 'react';
import { IMAGE_BASE_URL } from '../../../config';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Actor.css';




const Actor = ({ movieName, movieId, actor }) => {
  




  const POSTER_SIZE = "w154";
  
  const newTo = { 
    pathname: `/actor/${actor.id}`, 
    moviename: `${movieName}`,
    movieid: `${movieId}`
};
  return (
    
     
    <div className="rmdb-actor">
    
    <Link to={newTo}>
    
      <img
        src={actor.profile_path ? `${IMAGE_BASE_URL}${POSTER_SIZE}${actor.profile_path}` : './images/no_image.jpg'}
        title={movieName +' and '+ actor.name+"'s other work"}
        alt={actor.name}
        style={{borderRadius: '15px 15px 0px 0px'}}
        />
        
        </Link>

      <span className="rmdb-actor-name">{actor.name}</span>
      <span className="rmdb-actor-character">{actor.character}</span>
    </div>
  )
}

Actor.propTypes = {
  name: PropTypes.object,
  actorId: PropTypes.number,
  clickable: PropTypes.bool
}

export default Actor;