import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Header from '../elements/Header/Header';
import Home from '../Home/Home';
import Movie from '../Movie/Movie';
import Moviescripts from '../ScriptList/ScriptList';
import Watch from '../WatchMovie/Watch'; 
import ActorInfo from '../ActorInfo/ActorInfo';
import NotFound from '../elements/NotFound/NotFound';
import history from '../../history';
import '../elements/Header/Header';




const App = () => (
  
  <Router history={history}> 
    <React.Fragment>
      <Header />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/scripts/" component={Moviescripts} />
        <Route path="/:movieId" component={Movie} exact />
        <Route path="/:movieId/watch/providers" component={Watch} />
        <Route path="/actor/:actorId" component={ActorInfo}  />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  </Router>
)

export default App;

