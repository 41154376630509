import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../Header/Header.css';
import './Navigation.css';



const Navigation = ({  }) => ( 
  <div className="rmdb-navigation">
    <div className="rmdb-navigation-content">
    <Link to="/">
        <p  onClick={() => {
          return (
          document.getElementById("scriptMenuDiv").style.display = "inline",
          document.getElementById("adDiv").style.display = "none",
          document.title = "My Movie Scripts"
          )
        }}>Home</p>
        </Link>
      
      <p>/</p>
      <p id="moviename"></p> 
      <script>if(document.getElementById('moviename').innerHTML==''){
       setTimeout(() => { document.getElementById('moviename').innerHTML= document.getElementById('movTtl').innerHTML},100)
        }</script>
    </div>
    <div className="" style={{position:'absolute', right:'20px'}}>
    <Link to="/scripts/">
        <button className="rmdb-allscriptsbtn">All Scripts</button>
      </Link>
    </div>
  </div>
)

Navigation.propTypes = {
  movie: PropTypes.string
}

export default Navigation;