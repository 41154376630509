import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import allscripts from '../movies.json';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import Footer from '../App/Footer';
import $ from "jquery";
import '../elements/Navigation/Navigation.css';
import '../elements/Header/Header.css';


let moviescripts = allscripts;





class ScriptList extends Component {


  componentDidMount() {

    document.getElementById("scriptMenuDiv").style.display="none";
    document.getElementById("adDiv").style.display="inline";
   $("#mms_navbar").css({position: "fixed"})
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    this.setState({
      theposition: scrolled
    })
    // document.documentElement.scrollTop > 200 ?  $("#mms_navbar").css({position: "fixed"}).fadeIn(1000) : $("#mms_navbar").css({position: "relative"})
    }


  render(){

    let mscripts = moviescripts;


  return (
<div className="rmdb-home-grid">
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<div className="rmdb-navigation-content"><p style={{color:'black',fontSize:'36px',float:'left'}}>Scripts In Alphabetical Order</p></div>
<br />
<br />
<br />
<br />
<br />
<br />
            <ul style={{fontFamily: 'Abel',color:'black',fontSize: '18px'}}>
          <FourColGrid>
            {mscripts.map ( (element) => {
              {

                      return <Link to={{ pathname: '/'+element.moviescript_tmdb,  movieName: element.moviescript_title }}  style={{color:'black',textDecoration:'none'}}> {element.moviescript_tmdb ? <li title={'Download the '+element.moviescript_title+' script here'}>{element.moviescript_title}</li>:null} </Link> 

              }}
            )}
            </FourColGrid>
            </ul>
  <Footer />
  </div>
)}
}

export default ScriptList;
