import React, { Component } from 'react';
import { IMAGE_BASE_URL, POSTER_SIZE, BACKDROP_SIZE } from '../../../config';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import MovieThumb from '../MovieThumb/MovieThumb';
import moviescripts from '../../movies.json';
import './MovieInfo.css';




// eslint-disable-next-line
let hasScript = 'no';
let scriptdl = null;

class MovieInfo extends Component { 


    state = {
      moviescripts: [],
      hasScript: null
    }

    componentWillMount(){
      this.setState({
        hasScript: 'no'
        });
      //fetch("../../movies-sm.json").then(res => res.json()).then((data) => console.log(data))
      this.hasScript='no'
    }
  
    componentWillUnmount() {
    this.setState({
    hasScript: 'no'
    });
    hasScript = 'yes';
    }

    componentDidMount(){

    }

weHaveThatScript(){
return hasScript = 'yes';
}



getReleaseYear(date) {
  let year = '';
  for(let i=0;i<=3;i++){
    year += date.charAt(i);
  }
  return year
}

openScriptLink(url){
  window.open(url,"_blank");
}

loadAFV (iafvlink) {
  window.open("https://play.adfree.video/?q=" +iafvlink, "_blank");
}

loadIMDB (imdblink) {
  window.open("https://www.imdb.com/title/" +imdblink, "_blank");
}
g0toMovie (movtitle,year) {
  var str = movtitle.split(' ').join('+');
  window.open("https://g0.to/?q="+str+'+movie+'+year, "_blank");
}


  render() {
    const { movie, directors, writers } = this.props;
    //window.document.title = movie.title + ' - ' + movie.tagline + ' - Script/Screenplay @ MyMovieScripts.com'
 
  return(
    <div className="rmdb-movieinfo"
    style={{
      background: movie.backdrop_path ? `url('${IMAGE_BASE_URL}${BACKDROP_SIZE}${movie.backdrop_path}')` : '#000',
      opacity: movie.adult===true ? '1' : '1'
    }}
    >
    <div className="rmdb-movieinfo-content">
    <FontAwesome className="fa-imdb imdbBtn" style={{position:'absolute',right: '20px', top: '20px', fontSize:'42px', cursor:'pointer', zIndex: '100'}}  onClick={() => this.loadIMDB(movie.imdb_id) } name="imdb" title="Visit on IMDB" size="2x"  />
      <div className="rmdb-movieinfo-thumb">
        <MovieThumb
          image={movie.poster_path && movie.adult===false ? `${IMAGE_BASE_URL}${POSTER_SIZE}${movie.poster_path}` : 
          movie.adult===true ? '/images/adult-poster.png': './images/no_image.jpg'}
          clickable={false}
        />
      </div>
      <div className="rmdb-movieinfo-text">
        <h1 id="movTtl">{movie.title}</h1>
        {
          movie.tagline ? <h5 style={{color:'#ccc'}}>	&ldquo;{movie.tagline}&rdquo;</h5>  : null          
        }
        Released: <span id="releaseyear">{this.getReleaseYear(movie.release_date)}</span>

        { () => {this.top.moreInfo}}

        <h3>PLOT</h3>
        <p>{movie.overview}</p>
        <h3>IMDB RATING</h3>
        <div className="rmdb-rating">
          <meter min="0" max="100" optimum="100" low="40" high="70" value={ movie.vote_average * 10}></meter>
          <p className="rmdb-score">{movie.vote_average}</p>
        </div>
        
        {directors.length > 1 ? <h3>DIRECTORS</h3> : <h3>DIRECTOR</h3>}
        {directors.map( (element, i) => {
          return <p key={i} className="rmdb-director">{element.name}</p>
        })}
        {writers.length > 1 ? <h3>WRITERS</h3> : <h3>WRITER</h3>}
        {writers.map( (element, i) => {
          return <span key={i} className="rmdb-writer"> - {element.name}</span>
        })}
      </div>
      
      {moviescripts.map((element) => {
         if(movie.imdb_id === element.moviescript_imdb) { scriptdl = element.moviescript_url } 
         return movie.imdb_id === element.moviescript_imdb ?   this.hasScript='yes' :  null
        })}
      { 
          this.hasScript === 'yes'  ?   <div>
            <button clickable={true} onClick={() => this.g0toMovie(movie.title,this.getReleaseYear(movie.release_date))} className="btn" title={'more about '+movie.title } style={{ position:'absolute', top:'75px',right:'20px',  backgroundImage:'url(https://g0.to/logo.png)',backgroundSize:'contain', width:'40px',height:'40px',border:'0px',marginLeft:'15px',borderRadius:'5px',cursor:'pointer'}}></button> ,
          <FontAwesome className="fa-film" title="Download" name="download" style={{color:'aqua', cursor:'pointer'}} onClick={ () => this.openScriptLink(scriptdl) }  size="5x" /></div>
          : 
          <div>
            <button clickable={true} onClick={() => this.g0toMovie(movie.title,this.getReleaseYear(movie.release_date))} className="btn" title={'more about '+movie.title } style={{ position:'absolute', top:'75px',right:'20px',  backgroundImage:'url(https://g0.to/logo.png)',backgroundSize:'contain', width:'40px',height:'40px',border:'0px',marginLeft:'15px',borderRadius:'5px',cursor:'pointer'}}></button>
            <a href="mailto:scripts@mymoviescripts.com?subject=Script Link&body=Enter the link below. We'll check it out."><img src="//mymoviescripts.com/assets/submit-script.png" alt="Submit this script" className="fa-film" title="Submit this script option coming soon" name="logo" width="100" height="100" /></a>
          </div>
      }
       
    </div>
  </div>
)}}



MovieInfo.propTypes = {
  movie: PropTypes.object,
  directors: PropTypes.array,
  writers: PropTypes.array
}

export default MovieInfo;