import React, {Component } from 'react';
import { API_URL, API_KEY, IMAGE_BASE_URL,POSTER_SIZE } from '../../../config';
//import Spinner from '../../elements/Spinner/Spinner';
import Coverflow from 'react-coverflow';
import { StyleRoot } from 'radium';

var fn = function () {
  /* do you want */  
}

class Carousel extends Component{

  state = {
    cast: []
  }

  componentDidMount() {
    let endpoint = `${API_URL}person/${this.props.actorid}/movie_credits?api_key=${API_KEY}`
    fetch(endpoint)
    .then(res => res.json())
    .then((data) => {
      this.setState({ cast: data })
    })
    .catch(console.log)
  }


  getReleaseYear(date) {
    if(date){
    let year = '';
    for(let i=0;i<=3;i++){
      year += date.charAt(i);
    }
    return year
  } else { 
    return 'coming';
  }
}

posterRating(adult){
  if(adult===false) {
    return ''
  } else {
    return ''
  }
}
   
render() {
  const cast = this.state.cast.cast;
  //console.log(cast)
  const actorId = this.props.actorid;
  const movieId = this.props.movieid;
  const movieName = this.props.moviename;


  
    return (
    <div>
    {cast ?
      <StyleRoot>
      <Coverflow
        displayQuantityOfSide={2}
        navigation
        infiniteScroll
        enableHeading
        media={{
          '@media (max-width: 900px)': {
            width: '600px',
            height: '300px',
            backgroundColor:'white'
          },
          '@media (min-width: 900px)': {
            width: '90%',
            height: '650px',
            backgroundColor:'white'
          }
        }}
      > 




        {
          cast.map(movie =>
          movie.poster_path && movie.poster_path!='' && movie.adult===false  ?
           <img key={movie.id} src={`${IMAGE_BASE_URL}${POSTER_SIZE}${movie.poster_path}`}  alt={movie.character? 'Played '+movie.character +' ('+this.getReleaseYear(movie.release_date) +')': 'Uncredited'} title={movie.title} data-action={'/'+movie.id} style={{borderRadius: '15px 15px 0px 0px'}} /> 
           : 
             movie.adult===true ? 
             <img key={movie.id} src='/images/adult-poster.png' location={movie.title} alt={movie.character? movie.title +' ('+this.getReleaseYear(movie.release_date) +')': movie.title} title={movie.character? 'Played '+movie.character: 'Uncredited'} data-action={'/'+movie.id} style={{borderRadius: '15px 15px 0px 0px'}}  />
             :
             <img key={movie.id} src='/images/no-poster.png' location={movie.title} alt={movie.character? movie.title +' ('+this.getReleaseYear(movie.release_date) +')': movie.title} title={movie.character? 'Played '+movie.character: 'Uncredited'} data-action={'/'+movie.id} style={{borderRadius: '15px 15px 0px 0px'}}  /> 
        )}




 

</Coverflow>
<br />
<br />
<center style={{fontFamily:'arial black'}}> {this.props.theactor} </center>
<hr />
</StyleRoot>
      : null
    }
    </div>
    );

}}


export default Carousel;