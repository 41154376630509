import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './MovieThumb.css'; 


const MovieThumb = ({ image, movieId, movieName, clickable }) => (
  <div className="rmdb-moviethumb">
        
    {clickable ?
       <Link to={{ pathname: `/${movieId}`,  movieName: `${movieName}`}}>
       <img alt="" id={movieId} className="clickable" src={image}   style={{border: '1px solid #ccc'}} onClick={() =>{ 
          document.getElementById("scriptMenuDiv").style.display="none";
          document.getElementById("adDiv").style.display="inline";
       }} />
     </Link>
      :
      <img src={image} alt="moviethumb"/>
    }

  </div>
)

MovieThumb.propTypes = {
  image: PropTypes.string,
  movieId: PropTypes.number,
  movieName: PropTypes.string,
  clickable: PropTypes.bool
}

export default MovieThumb;