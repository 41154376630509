import React, { Component } from 'react';
import { API_URL, API_KEY } from '../../config';
import Navigation from '../elements/Navigation/Navigation';
import { Link } from 'react-router-dom';
import MovieInfo from '../elements/MovieInfo/MovieInfo';
import MovieInfoBar from '../elements/MovieInfoBar/MovieInfoBar';
import FourColGrid from '../elements/FourColGrid/FourColGrid';
import Actor from '../elements/Actor/Actor';
import Spinner from '../elements/Spinner/Spinner';
import $ from "jquery";
import './Movie.css';
import Footer from '../App/Footer';
import CarouselLatest from '../elements/Carousel/CarouselLatest';
import '../elements/SearchBar/SearchBar.css';

 


class Movie extends Component {



  state = {
    movie: null,
    actors: null,
    directors: [],
    writers: [],
    loading: false,
    hasScript: 'no'
  }

  componentDidMount() {

  
    
    document.getElementById("scriptMenuDiv").style.display="none";
    document.getElementById("adDiv").style.display="inline";

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    window.addEventListener('scroll', this.listenToScroll)
    // ES6 destructuring the props
    const { movieId, movieName } = this.props.match.params;
    //console.log(movieId);
    if (localStorage.getItem(`${movieId}`, `${movieName}`)) {
      let state = JSON.parse(localStorage.getItem(`${movieId}`, `${movieName}`))
      this.setState({ ...state })
    } else {
      this.setState({ loading: true })
      // First fetch the movie ...
      let endpoint = `${API_URL}movie/${movieId}?api_key=${API_KEY}&language=en-US`;
      this.fetchItems(endpoint);
    }

    // dosearch = () {
    //   qry = lowersearchbox.value.split(' ')
    //   qry = qry.join('+')
    //   location.href='/?q='+qry
    // } 

  }





    
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  




  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      theposition: scrolled
    })
    document.documentElement.scrollTop > 600 ?  $("#mms_navbar").css({position: "relative"}).fadeIn(1000) : $("#mms_navbar").css({position: "relative"})
    }




  fetchItems = (endpoint) => {
    // ES6 destructuring the props
    const { movieId } = this.props.match.params;

    fetch(endpoint)
    .then(result => result.json())
    .then(result => {

      if (result.status_code) {
        // If we don't find any movie
        this.setState({ loading: false });
      } else {
        this.setState({ movie: result }, () => {
          // ... then fetch actors in the setState callback function
          let endpoint = `${API_URL}movie/${movieId}/credits?api_key=${API_KEY}`;
          fetch(endpoint)
          .then(result => result.json())
          .then(result => {

            const directors = result.crew.filter( (member) => member.job === "Director");
            const writers = result.crew.filter( (member) => member.job === "Screenplay" || member.job === "Writer");

            this.setState({
              actors: result.cast,
              directors,
              writers,
              loading: false
            }, () => {
              localStorage.setItem(`${movieId}`, JSON.stringify(this.state));
            })
          })
        })
      }
    })
    .catch(error => console.error('Error:', error))
  }
 
  render() {
    // ES6 Destructuring the props and state
    const { movieName } = this.props.location;
    const { movie, directors, actors, writers, loading } = this.state;
    let watch = `${movie}/watch/providers/`;
  
    return (
      <div className="rmdb-movie">
        {movie ?
        <div>
          <Navigation movieName={movieName} />
          <MovieInfo movie={movie} directors={directors} writers={writers} imdbID={movie.imdb_id} />
          <MovieInfoBar time={movie.runtime} budget={movie.budget} revenue={movie.revenue} imdbID={movie.imdb_id}/>
        </div>
        : null }
        {actors ?
        <div className="rmdb-movie-grid">
        <Link to={watch}>  
          <button type="button"
            clickable={true}
            className="watch" 
            style={{ float:'right', marginRight: '20px', marginTop: '20px' }}
          >
            WATCH OPTIONS
          </button>
        </Link>
          <div style={{ float:'right',marginRight: '20px' }}>
          <div id="fb-root"></div>
         {(function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'))}
        
          <div className="fb-share-button" style={{ marginTop: '20px'}}
            data-href={window.location.href} 
            data-layout="button">
          </div>
        </div>
          <FourColGrid header={'Actors'}>
          
            {actors.map( (element, i) => (
              <Actor key={i} actor={element} movieName={movie.title} movieId={movie.id}/>
            ))}
          </FourColGrid>
          <br /><br />
        </div>
        : null }
        {!actors && !loading ? <h1>No movie found</h1> : null }
        {loading ? <Spinner /> : null}
          <br />
          <br />
          <br />  
        <div className="rmdb-searchbar-content">
          <div className="rmdb-searchbar" style={{ borderRadius: "5px" }} >
            <form method="get" action="/">
                  <input id="searchbox" placeholder="Search" style={{ backgroundColor: "#ccc", color:"#000",borderRadius: "15px", fontSize:"34px" }} className="rmdb-searchbar-input" name="q" type="text" value={this.value} />
            </form>
          </div>
          </div>
          <br />
          <br />
          <br />
        <CarouselLatest />
          <br />
          <br />  
         
        <div style={{margin:'auto auto',width:'500px'}}><a href="https://touchpoint.video" target="_blank" rel="noopener noreferrer"><img src="https://touchpoint.video/images/mms_468x60.png"  title="Visit Touchpoint Video and promote your business with animation" alt="Visit Touchpoint Video and promote your business with animation" /></a></div>  
        <br /><br />        
         
        <Footer />
    </div>
    )
  }
}

export default Movie;