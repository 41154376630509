import React, { Component } from 'react';
import Coverflow from 'react-coverflow';
import { StyleRoot } from 'radium';
import './Carousel.css';

var fn = function () {
    /* do you want */  
  }


const Carousel = ({ actorId, movieId, clickable }) => (



 
<StyleRoot>
<br />

    
    <Coverflow
      displayQuantityOfSide={2}
      navigation
      infiniteScroll
      enableHeading
      media={{
        '@media (max-width: 900px)': {
          width: '600px',
          height: '300px',
          backgroundColor:'white'
        },
        '@media (min-width: 900px)': {
          width: '90%',
          height: '650px',
          backgroundColor:'white'
        }
      }}
    > 
    <div
      onClick={() => fn()}
      onKeyDown={() => fn()}
      role="menuitem"
      tabIndex="0"
    >
      
      <img
        src='//image.tmdb.org/t/p/w500/udDclJoHjfjb8Ekgsd4FDteOkCU.jpg'
        alt='title or description'
        data-action='/475557'
        className="rnd"
        style={{ display: 'block', width: '100%',borderRadius: '15px 15px 0px 0px' }}
      />
    </div>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//image.tmdb.org/t/p/w500/y95lQLnuNKdPAzw9F9Ab8kJ80c3.jpg' alt='movie' data-action='/38700'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-12.jpg' alt='movie' data-action='/538225' />
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-11.jpg' alt='movie' data-action='/458156' />
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-10.jpg' alt='movie' data-action='/333339' />
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-9.jpg' alt='movie' data-action='/704' />
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-8.jpg' alt='movie' data-action='/1422'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-7.jpg' alt='movie' data-action='/103'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-6.jpg' alt='movie' data-action='/16869'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-5.jpg' alt='movie' data-action='/2649'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-4.jpg' alt='movie' data-action='/2907'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-3.jpg' alt='movie' data-action='/549'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-2.jpg' alt='movie' data-action='/680'/>
      <img style={{borderRadius: '15px 15px 0px 0px'}} src='//mymoviescripts.com/images/latest-movie-1.jpg' alt='movie' data-action='/107'/>
    </Coverflow>
    <div style={{textAlign:'center',fontFamily: 'arial'}}>
    <br />
    <br /><div style={{textAlign:'center',borderTop:'solid grey 1px',borderBottom:'solid grey 1px',width:'90%',margin:'5%'}}>LATEST MOVIE SCRIPTS</div>
<br />
<br />

    Navigate with arrows (click a pic to activate)</div>
  </StyleRoot>
  );

export default Carousel;