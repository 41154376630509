import React, { Component } from 'react';


class Footer extends Component {




    render (){
        return (
        <div style={{width:'100&',backgroundColor: 'rgba(0,0,0,0.2)'}}>
            <div style={{padding:'5px',bottom:'10px',color:'gref',fontSize:'10px',fontFamily:'arial',fontSpacing:'3px'}}> 
            &nbsp; 
            Powered by TMDB. 
            &nbsp; &copy;  <a href="/" style={{color:'grey'}}>MyMovieScripts.com</a>. 
            All Rights Reserved. 
            <span style={{float:'right',fontFamily: 'arial'}}>Built by 
            &nbsp; 
            <span style={{paddingRight:'20px',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={() => { window.location.href='https://stevs.net' }}>Stev</span>
            &nbsp; 
            </span>
            </div>
            <div>
                <iframe id="pav" src=""  style={{width:'100vw', height:'100vh',display:'none'}}></iframe>
            </div>
        </div>)
    }
}

export default Footer