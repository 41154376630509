import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import allscripts from '../../movies.json';

let moviescripts = allscripts;





class Header extends Component {

  state = {
    scriptIndex: this.state
  }

  componentDidUpdate(){
    setTimeout(()=> {
      document.getElementById("scriptsmenu").selectedIndex = localStorage.getItem('scriptIndex')
      },1)
  }

  handleChange = (e) => {
    
    if(document.getElementById("searchbox") && localStorage.getItem('scriptIndex')!==0)
    {
    document.getElementById("searchbox").value = e.target.value;
    document.getElementById("searchbox").focus();

    setTimeout(()=> { window.scroll({
      top: 670, 
      left: 0, 
      behavior: 'smooth'
    });
    },1100)
    localStorage.setItem('scriptIndex', document.getElementById("scriptsmenu").selectedIndex);
    } else {

    localStorage.setItem('scriptIndex', document.getElementById("scriptsmenu").selectedIndex);
    setTimeout(() => {
      window.document.title = "My Movie Scripts";
      window.location.href = '/';
    },100);
    setTimeout(() => {
      document.getElementById("scriptsmenu").selectedIndex = localStorage.getItem('scriptIndex')
    },1);
    }
  }
  handleChange1 = (e) => {
    
    localStorage.setItem('scriptIndex', document.getElementById("scriptsmenu").selectedIndex);
    setTimeout(() => {
      window.document.title = "My Movie Scripts";
      window.location.href = '/';
    },1);
    setTimeout(() => {
      document.getElementById("scriptsmenu").selectedIndex = localStorage.getItem('scriptIndex')
    },1);
  }
  
  resetScriptIndex(){
    document.getElementById("scriptMenuDiv").style.display="inline";
    document.getElementById("adDiv").style.display="none";
    localStorage.setItem('scriptIndex', 0);
    window.document.title = "My Movie Scripts";
    setTimeout(()=> { window.scroll({
      top: 600, 
      left: 0, 
      behavior: 'smooth'
    });
    },500)
    //window.location.href='/';
  }

  render(){

    let mscripts = moviescripts;
    let optionItems = mscripts.map((mscript,i) =>
            <option key={i} value={mscript.moviescript_title}>{mscript.moviescript_title}</option>
        );



  return (
  <div className="rmdb-header" id="mms_navbar" style={{position:'relative',zIndex: '1'}}>
    <div className="rmdb-header-content">
      <div className="col-7" style={{width: '70%'}}>
      <Link to="/">
        <img className="rmdb-logo" src="/images/mms_logo.png" alt="rmdb-logo" onClick={this.resetScriptIndex} />
      </Link>
      </div>
      <div className="col-5" style={{width: '40%', float: 'right', color: '#1C1C1C', textAlign: 'right', paddingTop: '40px', fontSize: '12px'}}>
 
      <div id="scriptMenuDiv" style={{display:'none',position:'absolute', right:'30px', top:'40px'}}> 
      <Link to="/scripts/">
        <button className="rmdb-allscriptsbtn">All Scripts</button>
      </Link>
      {document.getElementById("searchbox") ?  <select className="form-control" style={{ fontSize: '24px',width:'40%'}} id="scriptsmenu" onChange={this.handleChange1}>
          <option value="">Script Menu</option>
          {optionItems}
          </select> : <select className="form-control"  style={{ fontSize: '24px', color: 'black', width:'40%'}}  id="scriptsmenu" onChange={this.handleChange}>
          <option  value="">Script Menu</option>
          {optionItems}
          </select>
      }
      </div>
      <div id="adDiv" style={{display:'none', position:'absolute', right:'30px', top:'20px'}}>
            <a href="https://touchpoint.video" rel="noopener noreferrer"  target="_blank"><img src="https://touchpoint.video/images/mms_468x60.png" title="Visit Ad Free Video and watch your favorite videos in one player" alt="Visit Ad Free Video and watch your favorite videos in one player" /></a>
          </div>

      </div>
    </div>
  </div>
)}
}

export default Header;